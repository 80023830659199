.sp-buttton {
  display: flex;
  background: blue;
  color: white;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 20px;
  /* gap: 10px; */
  /* padding: 10px; */
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  border-radius: 8px;
}
.sp-table-head {
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  line-height: 24px;
  color: #252c32;
  background-color: #f9fafb;
}
.sp-table-data {
  font-weight: 400;
  size: 14px;
  line-height: 24px;
  color: #252c32;
}
.sp-check-box {
  display: none; /* Hide the native checkbox */
}

.sp-check-box-label {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #3b82f6;
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
}

/* When checkbox is checked */

/* You can add a tick effect when checked */
.sp-button {
  width: 153px;
}
.sp-button1 {
  width: 258px;
  background-color: #ffffff;
  color: #252c32;
  border: 2px solid #3b82f6;
}
.cat-button1 {
  width: 109px;
  background-color: #3b82f6;
  color: #ffffff;
}
.responsive-container {
  padding: 1rem;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .responsive-container {
    padding: 0.5rem;
  }


  .form-control,
  .btn {
    width: 100%; /* Make input and buttons full-width */
    margin-bottom: 0.5rem; /* Add spacing between elements */
  }

  .btn:last-child {
    margin-bottom: 10px; /* Remove margin for the last button */
  }
}
.text-head {
  color: #858d9d;
  

  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.006em;

  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
.sp-text {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4b5563;

}
.tabs-container {
  width: 100%; /* Ensure the container spans the full width of its parent */
  display: flex; /* Enables flexible alignment for children */
  justify-content: center; /* Center the tabs */
}

.nav-tabs {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping if needed for smaller screens */
  width: 100%; /* Make tabs span the container */
}

.nav-item {
  flex: 1 1 auto; /* Make each tab take an equal share of available space */
  text-align: center; /* Center the text inside each tab */
}

.nav-link {
  display: block; /* Ensure buttons take full width of their parent */
  width: 100%; /* Stretch to fit the width of the tab item */
  padding: 10px 15px; /* Add some padding for better aesthetics */
  white-space: nowrap; /* Prevent text wrapping within tabs */
}
.category-main {
  padding: 10px;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.flex-container label {
  flex: 1; /* Allows label to take up available space */
  min-width: 150px; /* Ensures the label doesn't shrink too much */
}

.flex-container button {
  flex-shrink: 0; /* Prevents button from shrinking */
}

@media (max-width: 768px) {
  .flex-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align items to the left */
  }

  .flex-container button {
    margin-top: 10px; /* Add spacing between label and button */
  }
}
.bordered-div {
  border: 1px solid #e5e9eb; /* Sets a gray border */
  border-radius: 2px; /* Adds rounded corners */
  padding: 5px; /* Adds spacing inside the div */
  margin: 5px; /* Adds spacing outside the div */
}
.edit-cat {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.022em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.cat-lab {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.table-container {
  width: 100%;
  margin: 0 auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  text-align: start; /* Centers text horizontally */
  vertical-align: middle;   
}

.table th {
  background-color: #F9FAFB; /* Light header background */
}

.table tbody tr:nth-child(odd) {
  background-color: #f2f2f2; /* Alternating row colors */
}

.table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.table .btn {
  margin: 0 auto;
}

/* .table img {
  display: block; 
  margin: 0 auto;
} */

.online-button {
  width: Fixed (86px) px;
  height: Hug (27px) px;
  padding: 6px;
  gap: 12px;
  border-radius: 6px;
  opacity: 0px;
}
.online-but-lab {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.006em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.order-head {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.006em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.order-tdata {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.006em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.online-sub-div {
  display: flex;

  gap: 10px;

  padding: 5px;
}
.online-sub-div input {
  width: 200px;
  height: 40px;
  padding: 10px 20px 10px 10px;
  gap: 142px;
  border-radius: 5px;
  border: 1.5px 0px 0px 0px;
  opacity: 0px;
}
.dropbtn {
  width: 200px;
}
.apply-btn {
  height: 10px;
}
.dropbutton {
  display: flex;
  width: 100%;
  text-align: left;
  background-color: #3b82f61a;
  align-items: center;
  gap: 3px;
}

/* @media screen and (max-width: 760px) {
  .cat-but1 {
    display: flex;
    flex-direction: column;
  }
} */
/* Default Button Styles */
.catbutton1 {
  padding: 0.5rem 1rem; /* Adjust padding for larger screens */
  font-size: 1rem; /* Default font size */
  font-family: "Inter", sans-serif;
  gap: 8px; /* Space between text and image */
  border-radius: 5px;
}

/* Icon Styles */
.catbutton1-icon {
  width: 16.67px;
  height: 16.67px;
}

/* Responsive Styles for Smaller Screens */
/* Default Button Styles */
.catbutton1 {
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  gap: 8px;
  border-radius: 5px;
  width: auto; /* Default width (adapts to content) */
}

/* Icon Styles */
.catbutton1-icon {
  width: 16.67px;
  height: 16.67px;
}

/* Responsive Styles for Smaller Screens */
@media screen and (max-width: 768px) {
  .cbu1 {
    width: 180px;
  }
  .catbutton1 {
    display: flex;
    width: 27%;
  }
  .catbutton2 {
    display: flex;
    justify-content: center;
  }
  .catbutton1-icon {
    width: 14px; /* Scale down icon size */
    height: 14px;
  }
  .actiondrop {
    width: 180px;
  }
  .dropmain {
    display: flex;
    justify-content: center;
  }
  .expbutton {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
}
.catbutton1 {
  display: flex;
  width: 180px;
  height: 38px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.expbutton {
  width: 180px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.bt-1 {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.custom-tab {
  margin-top: 3px;
  /* border: "1px solid #252c32"; */
  border-radius: 0 !important; /* Removes rounded corners */
  background-color: #fff!important;
  color: #252c32!important;

}

.custom-tab.active {
  border: none !important; 
  background-color: #53B7E8!important;
  color:white!important;
  font-weight: 500;
}
@media (max-width: 767px) {
  .cat-but1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .cat-but1 {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}

.btn-primary-tax {
  background-color: #3B82F6 !important;
  border-color: #3B82F6 !important;
  border-radius: 6px !important;
}


.btn-secondary{
  background-color: #858D9D;
  border-color: #858D9D !important;
  border-radius: 6px !important;
}
.border-custom-gray {
  border: 2px solid #F3F4F6 !important;
}
