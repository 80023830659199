.sp-buttton-b {
    display: flex;
    background: blue;
    color: white;
    align-items: center;
    justify-content: center;
   width: 46%;
    height: 20px;
    /* gap: 10px; */
    /* padding: 10px; */
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
  }
  .sp-table-head {
    font-weight: 400;
    size: 14px;
    line-height: 24px;
    color: #252c32;
    background-color: #f9fafb;
  }
  .sp-table-data {
    font-weight: 400;
    size: 14px;
    line-height: 24px;
    color: #252c32;
  }
  .sp-check-box {
    display: none; /* Hide the native checkbox */
  }
  
  .sp-check-box-label {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #3b82f6;
    cursor: pointer;
    background-color: white;
    border-radius: 3px;
  }
  
  /* When checkbox is checked */
  
  /* You can add a tick effect when checked */
  .sp-button2 {
    width: 46%;
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .sp-button1 {
    width: 70%;
    background-color: #ffffff;
    color: #252c32;
    border: 2px solid #3b82f6;
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .cat-button1 {
    width: 258px;
    background-color: #3b82f6;
    color: #ffffff;
  }
  .responsive-container {
    padding: 1rem;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    .responsive-container {
      padding: 0.5rem;
    }
  
    .d-flex {
       /* Stack elements vertically */
      align-items: stretch; /* Stretch input and buttons */
    }
  
    .form-control,
    .btn {
      width: 100%; /* Make input and buttons full-width */
      margin-bottom: 0.5rem; /* Add spacing between elements */
    }
  
    .btn:last-child {
      margin-bottom: 0; /* Remove margin for the last button */
    }
  }
  .text-head {
    color: #858d9d;
  
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* margin-left: 15px; */
  }
  .upd-var-btn {
    background-color: #ffffff; /* White background */
    color: #252c32; /* Text color */
    border: 2px solid #3b82f6 !important; /* Blue border */
  }
  
  
  .upd-var-btn:focus,
  .upd-var-btn:active {
    background-color: #ffffff !important; /* Prevent background color change */
    color: #252c32 !important; /* Prevent text color change */
    border: 2px solid #3b82f6 !important; /* Keep blue border */
    box-shadow: none !important; /* Remove shadow effect */
  }
  .sp-text {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b5563;
    /* margin-left: 15px; */
  }
  .btn-primary-tax {
    background-color: #3B82F6 !important;
    border-color: #3B82F6 !important;
    border-radius: 6px !important;
  }
  
  
  .btn-secondary{
    background-color: #858D9D;
    border-color: #858D9D !important;
    border-radius: 6px !important;
  }
  .custom-button {
    width: 100px !important; /* Default width */
}
.custom-button {
  width: 50px; /* Default width */
  max-width: 100%; /* Ensures the button doesn't exceed its container's width */
}

@media (max-width: 576px) { /* Small screens */
  .custom-button {
      width: 50px; /* Adjusted width for small screens */
      max-width: 100%; /* Ensures the button doesn't exceed its container's width */
  }
}
.input-responsive::placeholder {
  font-size: 14px;
}
.dropdown-button {
  width: 100%;
  font-size: 14px;
  min-width: 210px; /* Ensures it doesn’t shrink too much */
  max-width: 150px; /* Prevents it from stretching too much */
}

@media (max-width: 576px) { /* Small screens */
  .dropdown-button {
    font-size: 12px; /* Adjust for smaller screens */
    min-width: 150px;
  }
}

@media (max-width: 320px) { /* Extra small screens */
  .dropdown-button {
    font-size: 11px;
    min-width: 120px;
  }
}
/* Default input styling */
.form-control {
  width: 100%; /* Ensures full width inside its container */
  padding: 8px; /* Standard padding */
  font-size: 14px; /* Default font size */
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
  .col-md-2 {
    width: 50% !important; /* Make the input container full width */
    margin-bottom: 10px; /* Add spacing between inputs */
  }

  .form-control {
    font-size: 16px; /* Slightly larger font for better readability */
    padding: 10px; /* Increase padding for better touch experience */
  }
}
/* Index Cssss */


body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;}

:root {
  --background: #ffffff;
  --foreground: #000000;
  --custom-gray: #f2f2f2;
  --custom-blue: #cdeefd;
  --custom-gray-transparent: #f2f2f2bf;
  --custom-orange-transparent: #fde4cfbf;
  --custom-dark: #111827;
  --second-heading: #858d9d;
  --grey-color: #4b5563;
  --border-color: #f3f4f6;
  --disabled-button: #3b82f680;
  --primary-button: #3b82f6;
  --success: #119c2b;
  --text-black-color: #252c32;
  --table-head: #f3f4f6;
  --light-color: #3b82f61a;
  --dark: #000000;
  --input-text: #252c32;
}

body {
  background-color: var(--background);
  color: var(--foreground);
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.h-screen {
  height: 100vh;
}

.bg-white {
  background-color: white;
}
.bg-grey {
  background-color: #F9FAFB;
}

.text-customDark {
  color: var(--custom-dark);
}

.text-secondHeading {
  color: var(--second-heading);
}

.text-greycolor {
  color: var(--grey-color);
}

.border {
  border: 1px solid #E5E9EB;
}
.border-top {
  border-top: 1px solid #E5E9EB;;
}
.border-bottom {
  border-bottom: 1px solid #E5E9EB;
}
.border-none {
  border: none;
}
.rounded-lg {
  border-radius: 8px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.text-gray-600 {
  color: #4b5563;
}

.text-gray-500 {
  color: #6b7280;
}

.text-sm {
  font-size: 0.875rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.mt-4 {
  margin-top: 16px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 32px;
}

.w-full {
  width: 100%;

}

.w-96 {
  width: 24rem;
}

.max-w-sm {
  max-width: 24rem;
}

.bg-DisabledButton {
  background-color: #3B82F680;
}

.bg-PrimaryButton {
  background-color: #3B82F6;
}

.hover\:bg-blue-600:hover {
  background-color: #2563eb;
}

.border-2 {
  border-width: 2px;
}

.border-PrimaryButton {
  border-color: var(--primary-button);
}

.bg-custom-gradient {
  background: linear-gradient(
    to right,
    var(--custom-gray) 0%,
    var(--custom-blue) 50%,
    var(--custom-gray) 75%,
    var(--custom-orange-transparent) 100%
  );
}
.border-blue {
  border-color:  #3B82F6;
}
.border-left {
  border-left: 2px solid #3B82F6;;
}
.text-center {
  text-align: center;
}
.width165 {
  width: 165px;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content:space-between
}
.items-center {
  align-items: center;
}
.font-family {
  font-family: "Inter", sans-serif;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-31 {
  font-size: 31px;
}
.font-size-12 {
  font-size: 12px;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.rounded {
  border-radius: 4px;
}

.rounded-lg {
  border-radius: 8px;
}

.p-6 {
  padding: 24px;
}

.space-y-4 > * + * {
  margin-top: 16px;
}

.space-y-2 > * + * {
  margin-top: 8px;
}
.text-align-left {
  text-align: left;
}
.second-heading { 
  color: #858D9D
}
.dark-text {
  color: #111827;
}
.bg-light-color {
  background-color: #3B82F61A;
}
.green-color {
  color: #119C2B;
}
.widthhalf {
  width: "50%";
}
.flex-none {
  flex: none;
}
.blue-text {
  color: #3B82F6;
}

/* Over Loop css */

/* input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1.5px solid #3B82F6;
  border-radius: 2px;
  background-color: white;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

input[type="checkbox"]:checked {
  background-color: #3B82F6;
  border-color: #3B82F6;
}

input[type="checkbox"]::after {
  content: "";
  position: absolute;
  left: 3px;

  bottom: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked::after {
  display: block;
} */
/* Custom Radio Button */
input[type="radio"] {
  appearance: none; /* Remove default radio button appearance */
  width: 16px; /* Set width */
  height: 16px; /* Set height */
  border: 1px solid #3B82F6; /* Border color */
  border-radius: 50%; /* Make it circular */
  background-color: white; /* Background color */
  position: relative; /* Position the checkmark inside */
  cursor: pointer; /* Cursor pointer for interaction */
  display: inline-block; /* Display inline */
}

/* Checked state (when selected) */
input[type="radio"]:checked {
  background-color: white; /* Keep background white */
  border-color: #3B82F6; /* Border color when checked */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add the inner circle (check mark) when the radio is selected */
input[type="radio"]:checked::before {
  content: ""; /* Empty content for the pseudo-element */
  position: absolute; /* Position inside the radio */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  width: 8px; /* Set width of the inner circle */
  height: 8px; /* Set height of the inner circle */
  border-radius: 50%; /* Make the inner circle round */
  background-color: #3B82F6; /* Inner circle color */
}

.form-label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color:#000000
}
.form-check-label {
  margin-left: 10px;
}
.input-group{
border: 1.5px solid #F3F4F6;
border-radius: 8px;
padding: 5px;

align-items:center;
}
.cancel-bg {
  background-color: #858D9D;}
  
/* Default input styling */
.form-control {
  width: 100%; /* Ensures full width inside its container */
  padding: 8px; /* Standard padding */
  font-size: 14px; /* Default font size */
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
  .col-md-2 ,
  .col-md-4{
    width: 50% !important; /* Make the input container full width */
    margin-bottom: 10px; /* Add spacing between inputs */
  }

  .form-control {
    font-size: 16px; /* Slightly larger font for better readability */
    padding: 10px; /* Increase padding for better touch experience */
  }
}
