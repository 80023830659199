.sp-buttton-b {
    display: flex;
    background: blue;
    color: white;
    align-items: center;
    justify-content: center;
   width: 46%;
    height: 20px;
    /* gap: 10px; */
    /* padding: 10px; */
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
  }
  .sp-table-head {
    font-weight: 400;
    size: 14px;
    line-height: 24px;
    color: #252c32;
    background-color: #f9fafb;
  }
  .sp-table-data {
    font-weight: 400;
    size: 14px;
    line-height: 24px;
    color: #252c32;
  }
  .sp-check-box {
    display: none; /* Hide the native checkbox */
  }
  
  .sp-check-box-label {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px solid #3b82f6;
    cursor: pointer;
    background-color: white;
    border-radius: 3px;
  }
  
  /* When checkbox is checked */
  
  /* You can add a tick effect when checked */
  .sp-button2 {
    width: 46%;
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .sp-button1 {
    width: 70%;
    background-color: #ffffff;
    color: #252c32;
    border: 2px solid #3b82f6;
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .cat-button1 {
    width: 258px;
    background-color: #3b82f6;
    color: #ffffff;
  }
  .responsive-container {
    padding: 1rem;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    .responsive-container {
      padding: 0.5rem;
    }
  
    .d-flex {
       /* Stack elements vertically */
      align-items: stretch; /* Stretch input and buttons */
    }
  
    .form-control,
    .btn {
      width: 100%; /* Make input and buttons full-width */
      margin-bottom: 0.5rem; /* Add spacing between elements */
    }
  
    .btn:last-child {
      margin-bottom: 0; /* Remove margin for the last button */
    }
  }
  .text-head {
    color: #858d9d;
  
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.006em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* margin-left: 15px; */
  }
  .upd-var-btn {
    background-color: #ffffff; /* White background */
    color: #252c32; /* Text color */
    border: 2px solid #3b82f6 !important; /* Blue border */
  }
  
  
  .upd-var-btn:focus,
  .upd-var-btn:active {
    background-color: #ffffff !important; /* Prevent background color change */
    color: #252c32 !important; /* Prevent text color change */
    border: 2px solid #3b82f6 !important; /* Keep blue border */
    box-shadow: none !important; /* Remove shadow effect */
  }
  .sp-text {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4b5563;
    /* margin-left: 15px; */
  }
  .btn-primary-tax {
    background-color:  #3B82F6 !important;
    border-color: #3B82F6 !important;
    border-radius: 6px !important;
  }
  
  
  .btn-secondary{
    background-color: #858D9D;
    border-color: #858D9D !important;
    border-radius: 6px !important;
  }
  .custom-button {
    width: 100px !important; /* Default width */
}
.custom-button {
  width: 50px; /* Default width */
  max-width: 100%; /* Ensures the button doesn't exceed its container's width */
}

@media (max-width: 576px) { /* Small screens */
  .custom-button {
      width: 50px; /* Adjusted width for small screens */
      max-width: 100%; /* Ensures the button doesn't exceed its container's width */
  }
}
.input-responsive::placeholder {
  font-size: 14px;
}
.dropdown-button {
  width: 100%;
  font-size: 14px;
  min-width: 210px; /* Ensures it doesn’t shrink too much */
  max-width: 150px; /* Prevents it from stretching too much */
}

@media (max-width: 576px) { /* Small screens */
  .dropdown-button {
    font-size: 12px; /* Adjust for smaller screens */
    min-width: 150px;
  }
}

@media (max-width: 320px) { /* Extra small screens */
  .dropdown-button {
    font-size: 11px;
    min-width: 120px;
  }
}
/* Default input styling */
.form-control {
  width: 100%; /* Ensures full width inside its container */
  padding: 8px; /* Standard padding */
  font-size: 14px; /* Default font size */
}

/* Responsive design for smaller screens */
@media screen and (max-width: 768px) {
  .col-md-2 {
    width: 50% !important; /* Make the input container full width */
    margin-bottom: 10px; /* Add spacing between inputs */
  }

  .form-control {
    font-size: 16px; /* Slightly larger font for better readability */
    padding: 10px; /* Increase padding for better touch experience */
  }
}
.available_btn{
  background-color: #ffffff !important;
}
.Cards_class{
  background-color: #ffffff;
  box-shadow: #f9fafb !important;
  padding: 20px !important;
  margin: 12px !important;
 width: 48% !important;
 border-radius: 10px; 
}