.tax-btn{
justify-content: flex-end;
width: 1,323px;
height: 40px;
top: 151px;
left: 60px;
gap: 12px;
opacity: 0px;

}
.tab-frame{
    width: 1,340px;
    height: 39px;
    gap: 0px;
    opacity: 0px;
    background: var(--Selected, #F9FAFB);
}
.hor-tab{
    width: 188px;
height: 38px;
gap: 0px;
opacity: 0px;
border: 1px solid #000000
}
.add-btn{
    
    width: 100px;
    height: 40px;
     /* padding: 8px 87.71px 8px 87.69px;  */
    gap: 7.99px;
    border-radius: 6px 6px 6px 6px;
    opacity: 0px;
    
    background-color: #3B82F6;

}
.add-text{
  
/* font-family: Inter; */
font-size: 14px;
font-weight: 500;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
}
.add-text-show{
  font-size: 12px;
  color: #111827;


font-weight: 500;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.add-text1{
  font-size: 14px;

  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
color:#000000;
}
.sel-tax{   
padding: 10px 12px 10px 12px;
border-radius: 8px 8px 8px 8px;
    width: 130px;
height: 40px;
/* padding: 10px 12px 10px 12px; */
gap: 32px;

border: 1.5px 0px 0px 0px;
opacity: 0px;
color:  black;
border: 1.5px solid #F3F4F6;
/* font-family: Inter; */
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.sp-buttton {
    display: flex;
    background: blue;
    color: white;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 20px;
    /* gap: 10px; */
    /* padding: 10px; */
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
  }
  .text-head{
    /* font-family: Inter; */
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.006em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #252C32;


  }
  .custom-checkbox-label {
    display: inline-block;
    position: relative;
    width: 15px;
    height: 15px;
  }
  
  .custom-checkbox-box {
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid #3B82F6; /* Blue border */
    border-radius: 4px; /* Optional for rounded corners */
    background-color: white; /* White background when unchecked */
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition */
  }
  
  .custom-checkbox-label input {
    display: none; /* Hide the native checkbox */
  }
  
  .custom-checkbox-label input:checked + .custom-checkbox-box {
    background-color: #3B82F6; /* Blue background when checked */
    border-color: #3B82F6; /* Keep the border blue */
  }
  
  .custom-checkbox-label input:checked + .custom-checkbox-box::before {
    content: "✔"; /* White tick */
    color: white; /* White color for the tick */
    font-size: 12px; /* Adjust size of the tick */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the tick */
    font-weight: bold; /* Make the tick bold */
    line-height: 1;
  }
  
  .custom-thead {
    background-color: red;
  }
  
  .tax-name{
    
/* font-family: Inter; */
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.006em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
  }
.tax-dot-icon{
    width: 15.73px;
height: 15.73px;
top: 3.84px;
left: 4.43px;
gap: 0px;
opacity: 0px;
color:  #000000

}
.tax-lab{
color:  #858D9D;
font-size: 14px;
font-weight: 400;
line-height: "1.5"
  

}
.tax-radio-main{
    /* width: 561px;
height: 24px; */

align-items: center;
gap: 71px;
opacity: 0px;

}
.tax-add-txt{
    /* font-family: Inter; */
font-size: 14px;
font-weight: 400;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color:  #000000;
;

}
.btn-showall{
  background: #FFFFFF;
  border: 1px solid #F3F4F6;



}
.label-small {

  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;

}

.centered-icon {
  text-align: center; /* Horizontally centers the content */
  vertical-align: middle; /* Vertically centers the content */
}

.tax-type-container {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 20px; /* Space between label and radio group */
    margin-bottom: 24px;
  }
  
  .tax-type-label {
    /* font-family: Inter; */
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    
  }
  
  .tax-type-radio-group {
    display: flex;
    flex-direction: row; /* Horizontal layout */
    gap: 36px; /* Space between each radio button group */
  }
  
  .radio-option {
    display: flex;
    align-items: center; /* Align radio button and label vertically */
    gap: 8px; /* Space between radio button and label text */
  }
  .area-checkbox-container {
   
    gap: 20px;
    display: flex;
    align-items: center; /* Align items vertically */
  }
  
  .area-label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line if needed */
    gap: 15px; /* Space between each checkbox */
  }
  
  .checkbox-option {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between checkbox and label */
  }
  .cancel-btn{
    width: 100px;
    height:40px;
   
    
padding: 8px ;
gap: 7.99px;
border-radius: 6px 6px 6px 6px;
opacity: 0px;
background: #858D9D;


  }
  .save-btn{
   
    /* width: 200px;
    height:40px; */
   
   
padding: 8px 8px 8px 8px;

border-radius: 6px 6px 6px 6px;
opacity: 0px;
background:  #3B82F6;
;

  }
  .tax-inp{
    width: 175px;
height: 40px;
padding: 10px 20px 10px 10px;

border-radius: 8px 8px 8px 8px;
border: 1.5px 0px 0px 0px;
border: 1px solid #ccc;
color:  #858D9D;


  }
  .tax-type-label1{
   
font-size: 12px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color:  #858D9D;
;

  }
  .add-btn3{
    width: 100px;
    height: 40px;
     /* padding: 8px 87.71px 8px 87.69px;  */
    gap: 7.99px;
    border-radius: 6px 6px 6px 6px;
    opacity: 0px;
    
    background-color:  #FFFFFF;
    border: 1px solid #F3F4F6;
    

  }
  
  .inp-size{
    width: 156px;
    height: 40px;
    padding: 10px 20px 10px 10px;

border-radius: 8px 8px 8px 8px;
border: 1.5px 0px 0px 0px;
border: 1px solid #ccc;
color:  #858D9D;
  }
  .inp-size1{
    width: 250px;
    height: 42px;
    padding: 10px 20px 10px 10px;
gap: 6px;
border-radius: 8px 8px 8px 8px;
border: 1.5px 0px 0px 0px;
border: 1px solid #ccc;
color:  #858D9D;


  }
  .quantity-container {
    display: flex;
    align-items: center;
  }
  
  .quantity-btn {
    background-color: #007bff;
    border: none;
    width: 30px;
    height: 30px;
  border-radius: 60%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-family: "Inter", sans-serif;
    cursor: pointer;
    margin: 0 10px;
  }
  .plus-icon{
    height: 24px;
    width: 24px;
  }
  .heading-menu{
 
   
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:#000000;
    
  }
  .quantity-text {
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: bold;
    width: 60px;
    text-align: center;
  }
  
  .quantity-btn:focus {
    outline: none;
  }
 
 /* Adjust button width on smaller screens */
.btn-responsive {
  max-width: 150px; /* Set a maximum width */
}

@media (max-width: 768px) {
  .btn-responsive {
    max-width: 100px; /* Reduce button width for smaller screens */
    font-size: 0.9rem; /* Adjust font size */
    font-family: "Inter", sans-serif;
    padding: 0.5rem 0.75rem; /* Adjust padding */
  }
}

@media (max-width: 576px) {
  .btn-responsive {
    max-width: 150px; /* Further reduce button width for extra small screens */
    font-size: 0.8rem; /* Adjust font size */
    font-family: "Inter", sans-serif;
  }
}
/* Adjust input width on smaller screens */
.input-responsive {
  max-width: 100%; /* Ensure inputs take up available space */
}

@media (max-width: 768px) {
  .input-responsive {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    padding: 0.5rem 0.75rem; /* Adjust padding */
  }
}

@media (max-width: 576px) {
  .input-responsive {
    max-width: 150px;
    font-size: 0.8rem; /* Further reduce font size */
    padding: 0.4rem 0.6rem; /* Further adjust padding */
  }
}
.tax-header {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;
}
.text-custom {
  color: #858d9d;
  


}

.add-btn {
  flex-shrink: 0;
  width: auto;
  max-width: 150px; /* Restrict button width */
}

.sel-tax {
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .tax-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-btn,
  .sel-tax {
    width: 100%;
  }
}
.no-background-button {
  background: none; /* Removes background */
  border: none; /* Removes any border */
  padding: 0; /* Removes padding */
  display: inline-flex; /* Keeps the layout for the image */
  cursor: pointer; /* Ensures the button looks clickable */
}

.circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-cancel {
  padding: 10px 18px;
  border: 1px solid #F3F4F6;
  background-color: transparent; /* Adjust if needed */
  display: flex; /* If there's an icon or multiple elements */
  align-items: center;
  gap: 8px;
  border-radius: 6px; /* Adjust if needed */
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px #0A0D120D;
}
.border-tax {
  padding: 12px;
  border-radius: 6px;
  border: 1.5px solid #F3F4F6;
}
@media (max-width: 768px) {
  .input-container {
    width: 30% !important; /* Ensure 30% width */
    margin: 0 auto; /* Center inputs */
  }
}
.btn-primary-tax {
  background-color: #3B82F6 !important;
  border-color: #3B82F6 !important;
  border-radius: 6px !important;
}


.btn-secondary{
  background-color: #858D9D;
  border-color: #858D9D !important;
  border-radius: 6px !important;
}
.dropdown-font {
  font-size: 14px !important; /* Ensures the dropdown input text is 14px */
}

.dropdown-font .form-control::placeholder,
.dropdown-font .form-select::placeholder {
  font-size: 14px !important; /* Ensures the placeholder text is 14px */
  color: #6c757d; /* Optional: Adjust placeholder color */
}
.form-label1{
font-size: 14px;
font-weight: 600;
line-height: 21px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #4B5563;
}
.input-responsive::placeholder {
  font-size: 14px;
}
/* Hide increment/decrement buttons for number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Hide for Firefox */
}
input::placeholder {
  font-size: 14px;
}